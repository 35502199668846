<template>
  <div>
    <vx-card title="Inbound - Customer Return">
      <vs-tabs value="2">
        <vs-tab @click="goPurchaseOrder" index="0" label="Purchase Order"></vs-tab>
        <vs-tab @click="goIterWarehouse" index="1" label="Inter Warehouse"></vs-tab>
        <vs-tab index="2" label="Customer Return"></vs-tab>
        <vs-tab @click="goPodReturn" index="3" label="POD Return"></vs-tab>
      </vs-tabs>
      <div class="mt-3 ml-1 vx-row">
        <vs-tabs>
          <vs-tab label="Receive">
            <tab-received></tab-received>
          </vs-tab>
          <vs-tab label="Put-away">
              <tab-putaway></tab-putaway>
          </vs-tab>
          <vs-tab label="Completed">
              <tab-completed></tab-completed>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>
<script>
import TabReceived from "./TabReceived.vue";
import TabPutaway from "./TabPutaway.vue";
import TabCompleted from "./TabCompleted.vue";
export default {
  components: {
    TabReceived,
    TabPutaway,
    TabCompleted,
  },
  data() {
    return {
      activeTab: "Received",
      draw: 0,
      transactionDate: null,
      selectedSrcWarehouse: null
    }
  },
  methods: {
    handleFilter() {
      this.draw++;
    },
    goIterWarehouse() {
      this.$router.push({
        name: this.$store.state.inbound.interWarehouse.baseRouterName,
      });
    },
    goPurchaseOrder() {
      this.$router.push({
        name: this.$store.state.inbound.purchaseOrder.baseRouterName,
      });
    },
    goPodReturn() {
      this.$router.push({
        name: this.$store.state.inbound.podReturn.baseRouterName,
      });
    },
  }
}
</script>
