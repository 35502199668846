<template>
    <div>
        <div class="vx-row ml-1 mt-3">
          <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Warehouse Code</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <CustomMSelect :value="selectedWarehouses" base-url="/api/wms/v1/wms/inbound/receive/purchase-order/receive/warehouses" 
                        label="name" 
                        track-by="name" 
                        :multiple="true" 
                        :allowEmpty="false"
                        @selected="(val) => {
                            localStore('selectedWarehousesSalesReturn', JSON.stringify(val))
                            this.selectedWarehouses = val;
                        }">
                    </CustomMSelect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Customer/Ship-to Code</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect :value="selectedCustomer" base-url="/api/wms/v1/inbound/sales-return-receive/customer-code" 
                        label="code" 
                        track-by="id" 
                        :multiple="true" 
                        :allowEmpty="false"
                        @selected="(val) => {
                            localStore('selectedCustomerSalesReturn', JSON.stringify(val))
                            this.selectedCustomer = val;
                        }">
                    </CustomMSelect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>ETA Date</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <DateRangePicker 
                        :value="rangeDateValue" 
                        @rangeValue="updateRangeValue"
                    />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <Table
                    :draw="draw"
                    :transaction-date="eta" 
                    :range-date="rangeDateValue"
                    :source-warehouse-id="this.selectedSrcWarehousesIds"
                    :customer-code="this.selectedCustomerIds">
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import CustomMSelect from "./component/CustomSelect.vue";
import Warehouse from "@/components/Warehouse.vue";
import DateRangePicker from "./component/DateRangePicker.vue";
import Date from "@/components/Date.vue";
import Table from "./component/TablePutaway.vue";
import moment from "moment";
export default {
    components: {
        Warehouse,
        Date,
        CustomMSelect,
        DateRangePicker,
        Table
    },
    mounted() {
        this.selectedWarehouses = JSON.parse(localStorage.getItem('selectedWarehousesSalesReturn'))
        this.selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomerSalesReturn'))
        const storedRangeDateValue = JSON.parse(localStorage.getItem('rangeDateValueSalesReturn'));

        // Validasi format tanggal sebelum menggunakannya
        if (storedRangeDateValue && storedRangeDateValue.startDate && storedRangeDateValue.endDate) {
            this.rangeDateValue = {
                startDate: storedRangeDateValue.startDate,
                endDate: storedRangeDateValue.endDate
            };
        } else {
            this.rangeDateValue = { startDate: null, endDate: null };
        }
        console.log("TES DATA",this.rangeDateValue)
        let a = {
            id: 0,
            code: "All",
            name: "All"
        }

        if (this.selectedWarehouses == null) {
            this.selectedWarehouses = [a]
        }

        if (this.selectedCustomer == null) {
            this.selectedCustomer = [a]
        }
    },
    data() {
        return {
            activeTab: "Put-away",
            draw: 0,
            eta: moment().startOf('month').toDate(),
            rangeDateValue : null,
            selectedWarehouses: [],
            selectedSrcWarehousesIds: "0",
            whIds: "",
            selectedCustomer: [],
            selectedCustomerIds: "0",
            customerIds:""
        }
    },
    methods: {
        handleCreate() {
            this.$router.push({
                name: this.$store.state.outbound.simpleSto.baseRouterName + "-create",
            });
        },
        handleFilter() {
            this.draw++;
        },
        localStore(key,val){
            console.log("local store ", key, val)
            localStorage.setItem(key, val)
        },
        updateRangeValue(val) {
            const formattedRangeValue = {
                startDate: val.startDate,
                endDate: val.endDate
            };
            localStorage.setItem('rangeDateValueSalesReturn', JSON.stringify(formattedRangeValue));
            this.rangeDateValue = val;
        },
    },
    watch: {
        selectedCustomer(v) {
            var customerIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) customerIds.push(e.id) });
            }
            this.selectedCustomerIds = customerIds.join(",");
        },
        selectedWarehouses(v) {
            var whIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) whIds.push(e.id) });
            }
            this.selectedSrcWarehousesIds = whIds.join(",")
        }
    }
}
</script>
